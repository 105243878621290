var helpers = require("../../utils/helpers");

function createResourceMethods(gantt){

	var resourceTaskCache = {};

	gantt.$data.tasksStore.attachEvent("onStoreUpdated", function(){
		resourceTaskCache = {};
	});
	gantt.attachEvent("onBeforeGanttRender", function(){
		resourceTaskCache = {};
	});

	function getTaskBy(propertyName, propertyValue) {
		if (typeof propertyName == "function") {
			return filterResourceTasks(propertyName);
		} else {
			if (helpers.isArray(propertyValue)) {
				return getResourceTasks(propertyName, propertyValue);
			} else {
				return getResourceTasks(propertyName, [propertyValue]);
			}
		}
	}

	function filterResourceTasks(filter) {
		var res = [];
		gantt.eachTask(function (task) {
			if (filter(task)) {
				res.push(task);
			}
		});
		return res;
	}

	var falsyValuePrefix = String(Math.random());
	function resourceHashFunction(value){
		if (value === null){
			return falsyValuePrefix + String(value);
		}
		return String(value);
	}

	function getResourceTasks(property, resourceIds) {
		var res;
		var cacheKey = resourceIds.join("_") + "_" + property;
		var resourceHash = {};
		helpers.forEach(resourceIds, function(resourceId) {
			resourceHash[resourceHashFunction(resourceId)] = true;
		});

		if (!resourceTaskCache[cacheKey]) {
			res = resourceTaskCache[cacheKey] = [];
			gantt.eachTask(function (task) {
				if (task.type == gantt.config.types.project) return;
				if (property in task) {
					var resourceValue;
					if (!helpers.isArray(task[property])) {
						resourceValue = [task[property]];
					} else {
						resourceValue = task[property];
					}
					helpers.forEach(resourceValue, function(value) {
						if (resourceHash[resourceHashFunction(value)] || (value && resourceHash[resourceHashFunction(value.resource_id)])) {
							res.push(task);
						}
					});

				}
			});
		} else {
			res = resourceTaskCache[cacheKey];
		}

		return res;
	}

	function selectAssignments(resourceId, taskId, result){
		var property = gantt.config.resource_property;
		var owners = [];
		if (gantt.getDatastore("task").exists(taskId)) {
			var task = gantt.getTask(taskId);
			owners = task[property] || [];
		}

		if (!Array.isArray(owners)) {
			owners = [owners];
		}
		for (var i = 0; i < owners.length; i++) {
			if (owners[i].resource_id == resourceId) {
				result.push({task_id: task.id, resource_id:owners[i].resource_id, value:owners[i].value});
			}
		}
	}

	function getResourceAssignments(resourceId, taskId){
		// resource assignment as an independent module:
		// {taskId:, resourceId, value}
		// TODO: probably should add a separate datastore for these
		var assignments = [];
		var property = gantt.config.resource_property;
		if(taskId !== undefined){
			selectAssignments(resourceId, taskId, assignments);
		}else{
			var tasks = gantt.getTaskBy(property, resourceId);
			tasks.forEach(function(task){
				selectAssignments(resourceId, task.id, assignments);
			});
		}
		return assignments;
	}

	return {
		filterTasks: getTaskBy,
		getResourceAssignments: getResourceAssignments
	};
}

module.exports = function(gantt){
	var methods = createResourceMethods(gantt);

	gantt.getTaskBy = methods.filterTasks;
	gantt.getResourceAssignments = methods.getResourceAssignments;
	gantt.config.resource_property = "owner_id";
	gantt.config.resource_store = "resource";
	gantt.config.resource_render_empty_cells = false;

	/**
	 * these are placeholder functions that should be redefined by the user
	*/
	gantt.templates.histogram_cell_class = function(start_date, end_date, resource, tasks) {};
	gantt.templates.histogram_cell_label = function(start_date, end_date, resource, tasks) {
		return tasks.length + "/3";
	};
	gantt.templates.histogram_cell_allocated = function(start_date, end_date, resource, tasks) {
		return tasks.length / 3;
	};
	gantt.templates.histogram_cell_capacity = function(start_date, end_date, resource, tasks) {
		return 0;
	};



	gantt.templates.resource_cell_class = function(start, end, resource, tasks) {
		var css = "";
		if (tasks.length <= 1) {
			css = "gantt_resource_marker_ok";
		} else {
			css = "gantt_resource_marker_overtime";
		}
		return css;
	};

	gantt.templates.resource_cell_value = function(start, end, resource, tasks) {
		return tasks.length * 8;
	};
};


